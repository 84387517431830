import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import { useDispatch } from '../../../../contexts/ResumeContext';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';

const Objective = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChange = (content) => {
    dispatch({
      type: 'on_input',
      payload: {
        path: 'objective.body',
        value: content,
      },
    });
  };

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
      </div>

      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <p>
        Write 2-4 short & energetic sentences to interest the reader! Mention
        your role, experience & most importantly - your biggest achievements,
        best qualities and skills.
      </p>

      <Input
        type="textarea"
        label={t('shared.forms.summary')}
        path="objective.body"
        onChange={onChange}
        placeholder="e.g. Passionate science teacher with 8+ years of experience and a track record of ..."
      />
    </section>
  );
};

export default memo(Objective);
