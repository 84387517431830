import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { BsChevronLeft, BsGear } from 'react-icons/bs';
import React, { memo, useState, useRef } from 'react';
import * as styles from './Artboard.module.css';
import { useSelector } from '../../../contexts/ResumeContext';
import Button from '../../shared/Button';
import Castform from '../../../templates/Castform';
import Celebi from '../../../templates/Celebi';
import Gengar from '../../../templates/Gengar';
import Glalie from '../../../templates/Glalie';
import Onyx from '../../../templates/Onyx';
import Pikachu from '../../../templates/Pikachu';

const Artboard = ({ onGoBack, onSettings }) => {
  const state = useSelector();
  const { t } = useTranslation();
  const { id, name, metadata } = state;
  const { template } = metadata;
  const ref = useRef(null);
  const [zoom, setZoom] = useState(0.45);

  const handleDownloadPdf = () => {
    if (typeof window !== `undefined`) {
      window.location.href = `https://resumesmadehere.com/purchase/?id=${id}&type=resume`;
    }
  };

  const handleZoomIn = () => {
    let zoomIn = zoom === 0.45 ? 0.5 : zoom;
    zoomIn += 0.25;
    if (zoomIn > 2) zoomIn = 2;
    setZoom(zoomIn);
    ref.current.style.zoom = zoomIn;
  };

  const handleZoomOut = () => {
    let zoomOut = zoom - 0.25;
    if (zoomOut <= 0.5) zoomOut = 0.45;
    setZoom(zoomOut);
    ref.current.style.zoom = zoomOut;
  };

  return (
    <>
      <Helmet>
        <title>
          {name} | {t('shared.appName')}
        </title>
        <link
          rel="canonical"
          href={`https://app.resumesmadehere.com/app/builder/${id}`}
        />
      </Helmet>

      <div className="flex justify-around items-center mt-5">
        <button className={styles.btnclose} onClick={onGoBack}>
          <BsChevronLeft size="24" />
        </button>
        <Button
          icon={FaDownload}
          className={styles.button}
          onClick={handleDownloadPdf}
        >
          {t('modals.export.downloadPDF.heading')}
        </Button>
        <button className={styles.btnclose} onClick={onSettings}>
          <BsGear size="24" />
        </button>
      </div>
      <div ref={ref} className={styles.preview}>
        <div id="page" className={styles.container}>
          {template === 'onyx' && <Onyx data={state} />}
          {template === 'pikachu' && <Pikachu data={state} />}
          {template === 'gengar' && <Gengar data={state} />}
          {template === 'castform' && <Castform data={state} />}
          {template === 'glalie' && <Glalie data={state} />}
          {template === 'celebi' && <Celebi data={state} />}
        </div>
      </div>
      <div className={styles.bottom_bar}>
        <button
          id="zoomin"
          className={`${styles.btnzoom}`}
          onClick={handleZoomIn}
        >
          <AiOutlineZoomIn size={20} className={styles.zoom_icon} />
        </button>
        <button
          id="zoomout"
          className={`${styles.btnzoom}`}
          onClick={handleZoomOut}
        >
          <AiOutlineZoomOut size={20} className={styles.zoom_icon} />
        </button>
      </div>
    </>
  );
};

export default memo(Artboard);
