import { useTranslation } from 'react-i18next';
import React, { memo, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import PhotoUpload from '../../../shared/PhotoUpload';

const Profile = ({ id }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
      </div>

      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <Input
        name="subtitle"
        label={t('shared.forms.subtitle')}
        path="profile.subtitle"
      />

      <PhotoUpload />

      <div className="grid grid-cols-2 gap-6">
        <Input
          name="firstName"
          label={t('builder.profile.firstName')}
          path="profile.firstName"
        />
        <Input
          name="lastName"
          label={t('builder.profile.lastName')}
          path="profile.lastName"
        />
      </div>

      <Input
        name="email"
        label={t('shared.forms.email')}
        path="profile.email"
      />

      <Input
        name="phone"
        label={t('shared.forms.phone')}
        path="profile.phone"
      />

      <Input
        name="country"
        label={t('builder.profile.address.country')}
        path="profile.address.country"
      />

      <Input
        name="city"
        label={t('builder.profile.address.city')}
        path="profile.address.city"
      />

      {open && (
        <section>
          <hr />

          <Input
            name="addressLine1"
            label={t('builder.profile.address.line1')}
            path="profile.address.line1"
          />

          <Input
            name="pincode"
            label={t('builder.profile.address.pincode')}
            path="profile.address.pincode"
          />

          <Input
            name="drivingLicense"
            label={t('builder.profile.drivingLicense')}
            path="profile.drivingLicense"
          />

          <Input
            name="nationality"
            label={t('builder.profile.nationality')}
            path="profile.nationality"
          />

          <Input
            name="birthPlace"
            label={t('builder.profile.birthPlace')}
            path="profile.birthPlace"
          />

          <Input
            type="date"
            name="birthDate"
            label={t('builder.profile.birthDate')}
            path="profile.birthDate"
          />
        </section>
      )}

      <button id="edit_profile" className="btn-profile" onClick={handleOpen}>
        {!open && (
          <div>
            <span>Edit additional details</span>
            <BsChevronDown size={15} />
          </div>
        )}
        {open && (
          <div>
            <span>Hide additional details</span>
            <BsChevronUp size={15} />
          </div>
        )}
      </button>
    </section>
  );
};

export default memo(Profile);
